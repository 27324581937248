export class Applicant {
    Title: string | null;
    Firstname: string;
    Surname: string | null;
    App2ID?: number; 
    constructor( Title: string | null, Firstname: string, Surname: string | null, App2ID?: number) {
        this.Title = Title;
        this.Firstname = Firstname;
        this.Surname = Surname;
        this.App2ID = App2ID;
    }
}
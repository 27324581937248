import React, {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function Funder() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.CaseAssessorQ434:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(434, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ435:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(435, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ436:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(436, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ437:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(437, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ438:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(438, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ439:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(439, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ440:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(440, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ441:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(441, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ442:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(442, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ443:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(443, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ444:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(444, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ445:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(445, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ446:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(446, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ447:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(447, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ448:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(448, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ449:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(449, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ450:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(450, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ451:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(451, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ452:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(452, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ453:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(453, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ454:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(454, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ455:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(455, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ456:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(456, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ457:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(457, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ458:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(458, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ459:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(459, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ460:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(460, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ461:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(461, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("Funder", hasValueChanged);
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ434.toString()}
          label="How is loan funded"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(434)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(434)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ436.toString()}
          label="Equity Investor"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(436)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(436)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ438.toString()}
          label="Funding Investor 1"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(438)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(438)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ440.toString()}
          label="Funding Investor 3"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(440)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(440)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ442.toString()}
          label="Funding Investor 5"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(442)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(442)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ444.toString()}
          label="District Council"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(444)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(444)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ446.toString()} label="Spare 1" value={currentCaseAssessorQuestions.GetQuestionAnswer(446)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ448.toString()} label="Spare 3" value={currentCaseAssessorQuestions.GetQuestionAnswer(448)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ450.toString()} label="Spare 5" value={currentCaseAssessorQuestions.GetQuestionAnswer(450)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ452.toString()} label="Spare 7" value={currentCaseAssessorQuestions.GetQuestionAnswer(452)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ454.toString()} label="Spare 9" value={currentCaseAssessorQuestions.GetQuestionAnswer(454)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ456.toString()} label="Spare 11" value={currentCaseAssessorQuestions.GetQuestionAnswer(456)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ458.toString()} label="Spare 13" value={currentCaseAssessorQuestions.GetQuestionAnswer(458)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ460.toString()} label="Spare 15" value={currentCaseAssessorQuestions.GetQuestionAnswer(460)} setValue={handleState} />
        </div>
        <div className="form-column">
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ435.toString()}
          label="Funder"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(435)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(435)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ437.toString()}
          label="If sub-participation - how many Funding Investors"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(437)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(437)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ439.toString()}
          label="Funding Investor 2"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(439)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(439)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ441.toString()}
          label="Funding Investor 4"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(441)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(441)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ443.toString()}
          label="Funding Investor 6"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(443)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(443)}
        />
        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ445.toString()} label="Days in Arrears" value={currentCaseAssessorQuestions.GetQuestionAnswer(445)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ447.toString()} label="Spare 2" value={currentCaseAssessorQuestions.GetQuestionAnswer(447)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ449.toString()} label="Spare 4" value={currentCaseAssessorQuestions.GetQuestionAnswer(449)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ451.toString()} label="Spare 6" value={currentCaseAssessorQuestions.GetQuestionAnswer(451)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ453.toString()} label="Spare 8" value={currentCaseAssessorQuestions.GetQuestionAnswer(453)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ455.toString()} label="Spare 10" value={currentCaseAssessorQuestions.GetQuestionAnswer(455)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ457.toString()} label="Spare 12" value={currentCaseAssessorQuestions.GetQuestionAnswer(457)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ459.toString()} label="Spare 14" value={currentCaseAssessorQuestions.GetQuestionAnswer(459)} setValue={handleState} />
      </div>
    </div>
  );
}

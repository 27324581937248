import React from "react";
import "../../styles/FormReadonly.css";

function FormReadonly({
  fieldName,
  label,
  value,
  displayMode,
  isLinkedWithAPI
}: {
  fieldName: string;
  label: string;
  value: string | string[] | undefined;
  displayMode?: "list" | "span";
  isLinkedWithAPI?: boolean;
}) {
  const renderValue = () => {
    if (displayMode === "list" && Array.isArray(value)) {
      return (
        <ul className="form-readonly-list">
          {value.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'number' || (typeof value === 'string' && value.trim().length > 0)) {
      return <span className="form-readonly">{value}</span>;
    } else {
      return <span className="form-readonly">n/a</span>;
    }
  };

  return (
    <div className="input-container input-container-readonly">
      <label htmlFor={fieldName} className="input-header input-header-readonly">
        {label?.toUpperCase()}
      </label>
      {renderValue()}
    </div>
  );
}

export default FormReadonly;
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Private({ children }: { children: JSX.Element }) {
    const credentials = Cookies.get('Credentials');
    var auth = false;
    if(credentials)
    {
        auth = true;
    }

    if (auth !== true) {
      return <Navigate to="/login" replace />;
    }
  
    return children;
  }
  
  export default Private;

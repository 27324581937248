import UserInfo from "../../User/UserInfo";
import UpdateCaseAssessorMultiRecordList from "./UpdateCaseAssessorMultiRecordList";

export default class UpdateCaseAssessorRequest {
    jobCode: string;
    multiRecordList: UpdateCaseAssessorMultiRecordList[];
    UserInfo: UserInfo;

    constructor(jobCode: string, multiRecordList: UpdateCaseAssessorMultiRecordList[], userInfo: UserInfo) {
        this.jobCode = jobCode;
        this.multiRecordList = multiRecordList;
        this.UserInfo = userInfo;
    }
  }
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import iconClose from "../../images/iconClose.svg";
import iconCheck from "../../images/iconCheck.svg";
import { UpdateCaseContext } from "../../infrastructure/Context/UpdateCaseContext";

interface IProps {
  fieldName: string;
  answers: string[];
}

const FormSelect = ({ fieldName, label, value, values, setValue, isLinkedWithAPI = true }: { fieldName: string, label: string, value: string, values: string[], 
  setValue: (fieldName: string, value: string, hasValueChanged: boolean) => void, isLinkedWithAPI?: boolean }) => {
  const [selectValue, setSelectValue] = useState(value ? value : '');
  const [isModified, setIsModified] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  const initialRender = useRef(true);
  const { saveSuccess, setUnsavedChanges } = useContext(UpdateCaseContext);

  useEffect(() => {
    if (saveSuccess) {
      setIsModified(false);
      setInitialValue(selectValue); 
      setUnsavedChanges(false);
    }
  }, [saveSuccess, selectValue, setUnsavedChanges]);

  useEffect(() => {
    if (!initialRender.current) {
      setSelectValue(value);
      setIsModified(false);
      setUnsavedChanges(value !== initialValue);
    }
  }, [value, initialValue, setUnsavedChanges]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } 
  }, [value]);

  useEffect(() => {
    setIsModified(selectValue !== initialValue);
    setUnsavedChanges(selectValue !== initialValue);
  }, [selectValue, initialValue]);

 const handleSelect = (eventKey: string | null) => {
    const newValue = eventKey || '';
    setSelectValue(newValue);
    
    const wasModified = newValue !== initialValue || (initialValue === '' && newValue !== '');
    setValue(fieldName, newValue, wasModified);
    if (!initialRender.current || wasModified) {
      setIsModified(wasModified);
    }
  };

  const clearSelect = () => {
    setSelectValue('');
    setValue(fieldName, '', false);
    setIsModified(false);
    setUnsavedChanges(initialValue !== '');
  };

  const labelClass = isLinkedWithAPI ? "input-header" : "input-header to-do";

  return (
    <div className="input-container">
      <label htmlFor={fieldName} className={labelClass}>
        {label}
        {isModified && <span className="edit-indicator"></span>}
      </label>
      <DropdownButton id={fieldName} className="custom-dropdown" title={selectValue || ''} onSelect={handleSelect}>
        <div className="scroll-mask">
          {values.map((answer: string) => (
            <Dropdown.Item key={answer} className={selectValue === answer ? "selected-option" : "option"} eventKey={answer}>
               {answer ||  <span>&nbsp;</span>}
              {selectValue === answer ? <img src={iconCheck} className="check-icon" alt="Check icon" /> : <></>}
            </Dropdown.Item>
          ))}
        </div>
      </DropdownButton>
      <svg className="down-arrow-select" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon / Chevron[]">
          <path
            id="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.55806 7.05806C4.80214 6.81398 5.19786 6.81398 5.44194 7.05806L10 11.6161L14.5581 7.05806C14.8021 6.81398 15.1979 6.81398 15.4419 7.05806C15.686 7.30214 15.686 7.69786 15.4419 7.94194L10.4419 12.9419C10.1979 13.186 9.80214 13.186 9.55806 12.9419L4.55806 7.94194C4.31398 7.69786 4.31398 7.30214 4.55806 7.05806Z"
            fill="currentColor"
          />
        </g>
      </svg>
      <img src={iconClose} className="clear-val-btn-select" onClick={clearSelect} alt="Clear select value" />
    </div>
  );
};

export default FormSelect;
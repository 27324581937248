import React, {useContext} from "react";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormTextArea from "../Form/FormTextArea";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function Arrears() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.CaseAssessorQ460:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(460, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ461:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(461, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ462:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(462, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ463:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(463, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ464:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(464, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ465:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(465, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ466:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(466, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ467:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(467, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ468:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(468, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ469:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(469, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ470:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(470, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ471:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(471, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ472:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(472, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ473:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(473, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ474:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(474, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ475:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(475, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ476:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(476, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ477:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(477, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ478:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(478, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ479:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(479, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ480:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(480, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ481:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(481, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("Arrears", hasValueChanged);
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ461.toString()} label="Reason for Arrears" value={currentCaseAssessorQuestions.GetQuestionAnswer(461)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ463.toString()}
          label="Account in Default"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(463)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(463)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ465.toString()} label="Default Reason" value={currentCaseAssessorQuestions.GetQuestionAnswer(465)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ467.toString()} label="Breach letter issued" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(467)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ469.toString()}
          label="Solicitors instructed for Breach"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(469)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(469)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ471.toString()} label="Date Receiver Instructed" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(471)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ473.toString()} label="Enforcement Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(473)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ475.toString()} label="Amount Recovered" value={currentCaseAssessorQuestions.GetQuestionAnswer(475)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ477.toString()}
          label="Offered re-bridge defaulted loan"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(477)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(477)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ479.toString()} label="Spare 2" value={currentCaseAssessorQuestions.GetQuestionAnswer(479)} setValue={handleState} />
        </div>
        <div className="form-column">
        {/* <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ462.toString()}
          label="Step in Rights"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(462)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(462)}
        /> */}
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ464.toString()} label="Default Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(464)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ466.toString()} label="Default Value" value={currentCaseAssessorQuestions.GetQuestionAnswer(466)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ468.toString()}
          label="Breach - Date Solicitors instructed"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(468)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ470.toString()}
          label="Receiver Name"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(470)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(470)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ472.toString()}
          label="Account in Enforcement"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(472)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(472)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ474.toString()} label="Recovery Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(474)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ476.toString()}
          label="Source of Recovery"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(476)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(476)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ478.toString()} label="Spare 1" value={currentCaseAssessorQuestions.GetQuestionAnswer(478)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ480.toString()} label="Spare 3" value={currentCaseAssessorQuestions.GetQuestionAnswer(480)} setValue={handleState} />
      </div>
    </div>
  );
}

import React, { useState, ReactNode } from "react";
import {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormReadonly from "../Form/FormReadonlyInput";
import FormCurrencyInput from "../Form/FormCurrencyInput";

// Accordion component
interface AccordionProps {
    children: ReactNode;
    title: string;
}

// Accordion component
const Accordion = ({ children, title }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-security-item w-100">
            <div className={`accordion-title type-button ${isOpen ? 'open' : ''}`}  onClick={toggleAccordion}>
                <img src={process.env.PUBLIC_URL + '/images/icon-house.png'} height={48} className='house-icon' alt="House Icon" />
                {title}
                <img src={process.env.PUBLIC_URL + '/images/icon-arrow-down.png'} height={28} className='arrow-icon' alt="Arrow Icon" />
            </div>
            {isOpen && <div className="accordion-content">{children}</div>}
        </div>
    );
};

export default function PropertySecurity() {
  const {currentCase } = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean, iterator?: number) {
    if (
        typeof iterator !== 'undefined' && 
        currentCase.CaseApp &&
        currentCase.CaseApp.SecurityAddress &&
        currentCase.CaseApp.SecurityAddress[iterator]
    ) {
        switch (fieldName) {
            case "House name":
                currentCase.CaseApp.SecurityAddress[iterator].HouseName = value;
                break;
            case "Address1":
                currentCase.CaseApp.SecurityAddress[iterator].Address1 = value;
                break;
            case "Address2":
                currentCase.CaseApp.SecurityAddress[iterator].Address2 = value;
                break;
            case "Address3":
                currentCase.CaseApp.SecurityAddress[iterator].Address3 = value;
                break;
            case "Address4":
                currentCase.CaseApp.SecurityAddress[iterator].Address4 = value;
                break;
            case "PostCode":
                currentCase.CaseApp.SecurityAddress[iterator].Postcode = value;
                break;
            case "House Number":
                currentCase.CaseApp.SecurityAddress[iterator].HouseNumber = value;
                break;
            case "SecuirtyType":
                currentCase.CaseApp.SecurityAddress[iterator].SecuirtyType = value;
                break;

            case "ValuationType":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('4', value);
                break;
            case "SurveyorName":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('3', value);
                break;
            case "AssetType": // there are TWO AssetType fields
                currentCase.CaseApp.SecurityAddress[iterator].SecuirtyType = value;
                break;
            case "Charge":
                currentCase.CaseApp.SecurityAddress[iterator].SecuirtyCharge = value;
                break;
            case "Purchase Date":
                currentCase.CaseApp.SecurityAddress[iterator].SecuirtyPurchaseDate = value;
                break;
            case "Current Market Value":
                currentCase.CaseApp.SecurityAddress[iterator].SecuirtyMarketValuePerc = value;
                break;
            case "CMV 90 Day Value":
                currentCase.CaseApp.SecurityAddress[iterator].SecurityDV90Days = value;
                break;
            case "CMV 180 Day Value":
                currentCase.CaseApp.SecurityAddress[iterator].SecurityDV180Days = value;
                break;
            case "LTTC (Net)":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('5', value);
                break;
            case "MarketRent":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('2', value);
                break;
            case "Yield":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('6', value);
                break;
            case "No of Units":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('8', value);
                break;
            case "CurrentEPCRating":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('11', value);
                break;
            case "Region":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('7', value);
                break;
            case "Purchase Price":
                currentCase.CaseApp.SecurityAddress[iterator].SecuirtyPurchaseValue = value;
                break;
            case "Estimated Value":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('14', value);
                break;
            case "SurveyorCompany":
                // currentCase.CaseApp.SecurityAddress[iterator].HouseName = value;
                break;
            case "Valuation Inspection Date":
                currentCase.CaseApp.ApplicationProgressDates.MortOfferSent = value;
                break;
            case "First Mortgagee Loan Balance":
                currentCase.CaseApp.SecurityAddress[iterator].SecuirtyMortgageValue = value;
                break;
            case "AssetTypeAsIs":
                currentCase.CaseApp.SecurityAddress[iterator].SecuritySubType = value;
                break;
            case "GDV 90 Day Value":
                currentCase.CaseApp.SecurityAddress[iterator].SecurityDV90Days = value;
                break;
            case "GDV 180 Day Value":
                currentCase.CaseApp.SecurityAddress[iterator].SecurityGDV180Days = value;
                break;
            case "PropertyPreLet":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('10', value);
                break;
            case "Reinstatement Value":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('3', value);
                break;
            case "Total Sq Ft (As Is)":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('9', value);
                break;
            case "Total Sq Ft (To Be)":
                    currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('1', value);
                break;
            case "ActualValuation":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('12', value);
                break;
            case "PropertyUse":
                currentCase.CaseApp.SecurityAddress[iterator].SetCustomField('13', value);
                break;
        }
    }
    // console.log("handlestate - " + fieldName + ' - value ' + value );
  }

    return (
        <div className="m-2">
            {currentCase.CaseApp?.GetSecurityAddress().map((address, index) => (
                    <Accordion key={index} title={`${address.HouseName ?? ''} ${address.HouseNumber ?? ''} - ${address.Postcode ?? ''}`}>
                        <div className="form-layout">
                            <div className="form-column">
                                <FormReadonly fieldName={`House name`} label={`House name`} value={address.HouseName} />
                                <FormReadonly fieldName={`Address1`} label={`Address1`} value={address.Address1} />
                                <FormReadonly fieldName={`Address3`} label={`Address3`} value={address.Address3} />
                                <FormReadonly fieldName={`PostCode`} label={`PostCode`} value={address.Postcode} />
                            </div>
                            <div className="form-column">
                                <FormReadonly fieldName="House Number" label="House Number" value={address.HouseNumber} />
                                <FormReadonly fieldName="Address2" label="Address2" value={address.Address2} />
                                <FormReadonly fieldName="Address4" label="Address4" value={address.Address4} />
                            </div>
                        </div>
                        {/* <hr /> */}
                        {/* <div className="form-layout">
                            <div className="form-column">
                                <FormReadonly fieldName={"ValuationType"} label="Valuation Type" value={currentCase.CaseApp?.GetValuationDetails().ValuationType} />
                                <FormTextArea fieldName="Valuation Type" iterator={index} label="Valuer Type" value={address.GetCustomField('5')}  setValue={handleState} />
                            </div>
                            <div className="form-column">
                                <FormTextArea fieldName="SurveyorName" iterator={index} label="Surveyor Name" value={address.GetCustomField('4')}  setValue={handleState} />
                            </div>
                        </div> */}
                        <hr />
                        <div className="form-layout">
                            <div className="form-column">
                            <FormTextArea fieldName="Valuation Type" iterator={index} label="Valuer Type" value={address.GetCustomField('5')}  setValue={handleState} />
                            <FormTextArea fieldName="SurveyorName" iterator={index} label="Surveyor Name" value={address.GetCustomField('4')}  setValue={handleState} />
                                <FormTextArea fieldName={`AssetType`} iterator={index} label={`Asset Type`} value={address.SecuirtyType} setValue={handleState} />
                                <FormTextArea fieldName={`Charge`} iterator={index} label={`Charge`} value={address.SecuirtyCharge} setValue={handleState} />
                                <FormTextArea fieldName={`AssetType`} iterator={index} label={`Asset Type (To be)`} value={address.SecurityStatus} setValue={handleState} />
                                <FormTextArea fieldName={`Purchase Date`} iterator={index} label={`Purchase Date`} value={address.SecuirtyPurchaseDate} setValue={handleState} />
                                <FormTextArea fieldName="Current Market Value" iterator={index} label="Current Market Value" value={address.SecuirtyMarketValuePerc} setValue={handleState} />
                                <FormTextArea fieldName="CMV 90 Day Value" iterator={index} label="CMV 90 Day Value" value={address.SecurityDV90Days.toString()} setValue={handleState} />
                                <FormTextArea fieldName="CMV 180 Day Value" iterator={index} label="CMV 180 Day Value" value={address.SecurityDV180Days.toString()} setValue={handleState} />
                                {/* <FormTextArea fieldName="LTTC (Net)" iterator={index} label="LTTC (Net)" value={address.GetCustomField('5')} setValue={handleState} /> */}
                                <FormTextArea fieldName="MarketRent" iterator={index} label="Market Rent" value={address.GetCustomField('2')} setValue={handleState} />
                                <FormTextArea fieldName="Yield" iterator={index} label="Yield" value={address.GetCustomField('6')} setValue={handleState} />
                                <FormTextArea fieldName="No of Units" iterator={index} label="No of Units" value={address.GetCustomField('8')} setValue={handleState} />
                                <FormTextArea fieldName={"CurrentEPCRating"} iterator={index} label="Current EPC Rating" value={address.GetCustomField('11')} setValue={handleState} />
                            </div>
                            <div className="form-column">
                                <FormTextArea fieldName="Region" iterator={index} label="Region" value={address.GetCustomField('7')} setValue={handleState}  />
                                <FormTextArea fieldName="Purchase Price" iterator={index} label="Purchase Price" value={address.SecuirtyPurchaseValue} setValue={handleState}  />
                                <FormTextArea fieldName="Estimated Value" iterator={index} label="Estimated Value" value={address.GetCustomField('14')} setValue={handleState}  />
                                {/* <FormTextArea fieldName={"SurveyorCompany"} iterator={index} label="Surveyor Company" value={currentCase.CaseApp?.GetValuationDetails().Surveyor ?? ""} setValue={handleState}  /> */}
                                {/* <FormTextArea fieldName="Valuation Inspection Date" iterator={index} label="Valuation Inspection Date" value={currentCase.CaseApp?.ApplicationProgressDates?.MortOfferSent?.toString() || ''} setValue={handleState} /> */}
                                <FormTextArea fieldName="First Mortgagee Loan Balance" iterator={index} label="First Mortgagee Loan Balance" value={address.SecuirtyMortgageValue} setValue={handleState} />
                                <FormTextArea fieldName={"AssetTypeAsIs"} iterator={index} label="Asset Type (As is)" value={address.SecuritySubType} setValue={handleState} />
                                <FormTextArea fieldName="GDV 90 Day Value" iterator={index} label="GDV 90 Day Value" value={address.SecurityGDV90Days.toString()} setValue={handleState} />
                                <FormTextArea fieldName="GDV 180 Day Value" iterator={index} label="GDV 180 Day Value" value={address.SecurityGDV180Days.toString()} setValue={handleState} />
                                <FormTextArea fieldName={"PropertyPreLet"} iterator={index} label="Property Pre-Let" value={address.GetCustomField('10')} setValue={handleState} />
                                <FormTextArea fieldName="Reinstatement Value" iterator={index} label="Surveyor Company" value={address.GetCustomField('3')} setValue={handleState} />
                                <FormTextArea fieldName="Total Sq Ft (As Is)" iterator={index} label="Total Sq Ft (As Is)" value={address.GetCustomField('9')} setValue={handleState} />
                                <FormTextArea fieldName="Total Sq Ft (To Be)" iterator={index} label="Total Sq Ft (To Be)" value={address.GetCustomField('1')} setValue={handleState} />
                                <FormTextArea fieldName={"ActualValuation"} iterator={index} label="Actual Valuation/Sale Price" value={address.GetCustomField('12')} setValue={handleState} />
                                <FormTextArea fieldName={"PropertyUse"} iterator={index} label="Property Use" value={address.GetCustomField('13')} setValue={handleState} />
                            </div>
                        </div>
                    </Accordion>
            ))}
        </div>
    );
}

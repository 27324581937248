import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UpdateCaseContext } from "../../infrastructure/Context/UpdateCaseContext";
import UserInfo from "../../models/User/UserInfo";
import UpdateCaseAssessorRequest from "../../models/CaseAssessor/UdateCaseAssessor/UpdateCaseAssessorRequest";
import UpdateCaseAssessorMultiRecordList from "../../models/CaseAssessor/UdateCaseAssessor/UpdateCaseAssessorMultiRecordList";
import saveIcon from '../../images/success.svg'; 
import errorIcon from '../../images/error.svg';
import Case from "../../models/Case/UpdateCase/Case";
import { ValuationDetails } from "../../models/Case/ValuationDetails";

const ActionButtons = () => {
  const navigate = useNavigate();
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, unsavedChanges, setUnsavedChanges} = useContext(UpdateCaseContext);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const { setSaveSuccess } = useContext(UpdateCaseContext);

  const handleCancel = () => {
    navigate('/');
  };

  const save = async () => {
    var credentials = Cookies.get("Credentials")?.split("|");
    if (!credentials) credentials = [];
    var userInfo: UserInfo = new UserInfo(credentials[0], credentials[1]);

    currentCase.UserInfo = userInfo;

    let currentCaseToUpdate = JSON.parse(JSON.stringify(currentCase)) as Case;
    if(currentCaseToUpdate.CaseApp != null) {
      currentCaseToUpdate.CaseApp.ValuationDetails = [];
      currentCaseToUpdate.CaseApp.SecurityAddress = currentCase.CaseApp?.SecurityAddress ?? [];
    }

    const request: RequestInit = {
      method: "POST",
      body: JSON.stringify(currentCaseToUpdate),
      headers: {
        "Content-Type": "application/json",
      },
    };
   
    try {
      const responseUpdateCase = await fetch("https://my9ycap.co.uk/Webservices/BrightOfficeAPI.asmx/UpdateCase", request);
      const updateCaseResult = await responseUpdateCase.json();
      if (!responseUpdateCase.ok) throw new Error('UpdateCase failed');
  
      let updateCaseAssessor = new UpdateCaseAssessorRequest(
        currentCase.CaseApp?.ApplicantID || '', 
        [new UpdateCaseAssessorMultiRecordList(currentCaseAssessorQuestions.Records)], 
        userInfo
      );
  
      const request1 = {
        method: "POST",
        body: JSON.stringify(updateCaseAssessor),
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const responseUpdateCaseAssessorJSON = await fetch("https://my9ycap.co.uk/Webservices/BrightOfficeAPI.asmx/UpdateCaseAssessorJSON", request1);
      if (!responseUpdateCaseAssessorJSON.ok) throw new Error('UpdateCaseAssessorJSON failed');
  
      const dataUpdateCaseAssessorJSON = await responseUpdateCaseAssessorJSON.json();
  
      if (updateCaseResult.d.UpdateResult.SuccessCode === 0 && dataUpdateCaseAssessorJSON.d.EnquiryResult.SuccessCode === 0) {
        setShowNotification(true);
        setNotificationMessage("Changes have been saved");
        setSaveSuccess(true);
        updateTabsModified("all", false);
        setUnsavedChanges(false); 
        setTimeout(() => {
          setShowNotification(false);
          setSaveSuccess(false);
        }, 3000)
      } else {
        throw new Error('Partial error in saving data');
      }
    }catch (error) {
      setShowNotification(true);
      setNotificationMessage("Changes not saved");
      setTimeout(() => {
        setShowNotification(false);
      }, 3000)
    }
  };

  return (
    <div className="container-fluid action-buttons-wrapper">
      {showNotification && (
  <div className={`save-notification ${notificationMessage.includes("not saved") ? "save-notification-error" : "save-notification-success"}`}>
          <img
            src={notificationMessage.includes("not saved") ? errorIcon : saveIcon}
            alt="Notification Icon"
            className="save-notification-icon"
          />
          <div className="save-notification-content">
            <span className="save-notification-message">{notificationMessage}</span>
            {notificationMessage.includes("not saved") && (
              <span className="additional-error-message">Please try again or contact support team</span>
            )}
          </div>
          <div className="save-notification-dismiss" onClick={() => setShowNotification(false)}>
            &times;
          </div>
        </div>
      )}
   <div className="actions-container">
    <div className="unsaved-changes-container">
      {unsavedChanges && (
        <div className="unsaved-changes-notification">
          <span className="unsaved-changes-dot"></span>
          <span className="unsaved-changes-text">You have unsaved changes</span>
        </div>
      )}
    </div>
    <div className="action-buttons">
      <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
      <button className="btn-save" onClick={save}>Save</button>
    </div>
  </div>
</div>
  );
};

export default ActionButtons;

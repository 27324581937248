import moment from "moment";
import SecurityCustomField from "./SecurityCustomField";

export default class SecurityAddress {
    public Code: string = '';
    public HouseName: string = '';
    public HouseNumber: string = '';
    public Address1: string = '';
    public Address2: string = '';
    public Address3: string = '';
    public Address4: string = '';
    public Postcode: string = '';
    public SecuirtyCharge: string = '';
    public SecurityStatus: string = '';
    public SecuirtyPurchaseValue: string = '';
    public SecuirtyPurchaseDate: string = '';
    public SecuirtyMarketValue: string = '';
    public SecuirtyMortgageValue: string = '';
    public SecurityCustomField: SecurityCustomField[] = [];
    public SecurityDV90Days: string = '';
    public SecurityGDV90Days: string = '';
    public SecurityDV180Days: string = '';
    public SecurityGDV180Days: string = '';
    public SecurityGDV: string = '';
    public SecuirtyType: string = '';
    public SecuritySubType: string = '';
    public SecuirtyMarketValuePerc : string = '';
    
    constructor(Code: string = '', HouseName: string = '', HouseNumber: string = '', Address1: string = '', Address2: string = '', Address3: string = '', Address4: string = '', Postcode: string = '',
                SecuirtyCharge: string = '', SecurityStatus: string = '', SecuirtyPurchaseValue: string = '', SecuirtyPurchaseDate: string = '', SecuirtyMarketValue: string = '', 
                SecuirtyMortgageValue: string = '', SecurityCustomField: SecurityCustomField[] = [], SecurityDV90Days: string = '', SecurityGDV90Days: string = '',
                SecurityDV180Days: string = '', SecurityGDV180Days: string = '', SecurityGDV: string = '', SecuirtyType: string = '', SecuritySubType: string = '', SecuirtyMarketValuePerc : string = '')
    {
        this.Code = Code;
        this.HouseName = HouseName;
        this.HouseNumber = HouseNumber;
        this.Address1 = Address1;
        this.Address2 = Address2;
        this.Address3 = Address3;
        this.Address4 = Address4;
        this.Postcode = Postcode;
        this.SecuirtyCharge = SecuirtyCharge;
        this.SecurityStatus = SecurityStatus;
        this.SecuirtyPurchaseValue = SecuirtyPurchaseValue != null ? SecuirtyPurchaseValue.toString() : '';
        this.SecuirtyPurchaseDate = SecuirtyPurchaseDate;
        this.SecuirtyMarketValue = SecuirtyMarketValue != null ? SecuirtyMarketValue.toString() : '';
        this.SecuirtyMortgageValue = SecuirtyMortgageValue != null ? SecuirtyMortgageValue.toString() : '';
        this.SecurityCustomField = SecurityCustomField;
        this.SecurityDV90Days = SecurityDV90Days != null ? SecurityDV90Days.toString() : '';
        this.SecurityGDV90Days = SecurityGDV90Days != null ? SecurityGDV90Days.toString() : '';
        this.SecurityDV180Days = SecurityDV180Days != null ? SecurityDV180Days.toString() : '';
        this.SecurityGDV180Days = SecurityGDV180Days != null ? SecurityGDV180Days.toString() : '';
        this.SecurityGDV = SecurityGDV != null ? SecurityGDV.toString() : '';
        this.SecuirtyType = SecuirtyType;
        this.SecuritySubType = SecuritySubType;
        this.SecuirtyMarketValuePerc = SecuirtyMarketValuePerc;   
    }

    public GetCustomField(fieldNo: string) : string {
        var customField = this.SecurityCustomField.find(z => z.CustomFieldNo == fieldNo)
        return customField != null ? customField?.CustomFieldValue : '';
    }

    public SetCustomField(fieldNo: string, fieldValue: string) : object {
        var customFieldIndex = this.SecurityCustomField.findIndex(z => z.CustomFieldNo == fieldNo);
        if (customFieldIndex !== -1) {
            this.SecurityCustomField[customFieldIndex].CustomFieldValue = fieldValue;
        } else {
            // If the custom field with the given field number does not exist, create a new one.
            this.SecurityCustomField.push({ 'CustomFieldNo': fieldNo, CustomFieldValue: fieldValue });
        }
        return this.SecurityCustomField;
    }
    
  }
export class ValuationDetails {
    public SurveyRequested: string;
    public Surveyor: string = '';
    public ValuationType: string = '';

    constructor(SurveyRequested: string = '', Surveyor: string = '', ValuationType: string = '') {
        this.SurveyRequested = SurveyRequested;
        this.Surveyor = Surveyor != null ? Surveyor : '';
        this.ValuationType = ValuationType != null ? ValuationType : '';
    }
}

import moment from "moment";
import CaseAssessorQuestionRecord from "./CaseAssessorQuestionRecord";
import CaseAssessorDynamicQuestionRecord from "./CaseAssessorDynamicQuestionRecord";

export default class CaseAssessorQuestions {
    Records: CaseAssessorQuestionRecord[];
    DynamicData?: CaseAssessorDynamicQuestionRecord[];

    constructor(Records: CaseAssessorQuestionRecord[], DynamicData?: CaseAssessorDynamicQuestionRecord[]) {
        this.Records = Records;
        this.DynamicData = DynamicData;
    }

    GetQuestionAnswer(QNo: number) {
        var value = "";
        var record = this.Records.find((z) => z.Qno == QNo);
        if (record)
          value = record.Answer;
    
        return value || '';
    }

    GetQuestionAnswerList(QNo: number): string[] {
      const record = this.Records.find((record) => record.Qno === QNo);
      let answerList = record && record.AnswerList ? record.AnswerList : [""];
      
      return answerList;
    }

    GetDynamicQuestionAnswerList(FieldNo: number): string[] {
      const record = this.DynamicData ? this.DynamicData.find((record) => record.FieldNo == FieldNo) : null;
      let answerList = record && record.DataTableOptions ? record.DataTableOptions.map(option => option.Text) : [""];
      return answerList;
    }

    GetQuestionAnswerAsDate(QNo: number): Date | null {
        const answer = this.GetQuestionAnswer(QNo);
    
        if (!answer || typeof answer !== 'string') {
            return null;
        }
    
        const expectedFormats = [
            moment.ISO_8601,
            'YYYY-MM-DD',
            'DD-MM-YYYY',
            'MM/DD/YYYY',
            'DD/MM/YYYY',
        ];
    
        for (const format of expectedFormats) {
            const parsedDate = moment(answer, format, true);
            if (parsedDate.isValid()) {
                return parsedDate.toDate();
            }
        }
    
        const netDateMatch = answer.match(/\/Date\((\d+)\)\//);
        if (netDateMatch) {
            const milliseconds = parseInt(netDateMatch[1], 10);
            return moment(milliseconds).isValid() ? moment(milliseconds).toDate() : null;
        }

        return null;
    }

    UpdateQuestionAnswer(QNo: number, value: string) {
      var questionAnswer = this.Records.find((z) => z.Qno == QNo);
      if (questionAnswer)
        questionAnswer.Answer = value;
    }
  }